/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
     -moz-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  -moz-box-flex: 1;
       flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  -moz-box-align: start;
       align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  -webkit-transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: -moz-box;
  display: flex;
  -moz-box-flex: 1;
       flex: auto;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
       flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  -moz-box-flex: 0;
       flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  -moz-box-flex: 1;
       flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  -moz-box-ordinal-group: 2;
       order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}

